import { OnboardingRouterPath } from "@src/app/routing/constant/onboardingRoute";
import { useLogEffect } from "@src/appV2/lib/analytics/useLogEffect";
import { FC } from "react";
import { Redirect } from "react-router-dom";

import "../style.scss";
import { OnBoardingComponentProps } from "./model";

const AgentQualification: FC<OnBoardingComponentProps> = ({ agent, nextStagePath }) => {
  useLogEffect("Deprecated onboarding agentQualification page visited", agent || {});

  return <Redirect to={OnboardingRouterPath.ONBOARDING_LICENSE} />;
};

export { AgentQualification };
